import { render, staticRenderFns } from "./navigation.vue?vue&type=template&id=5726be5e&scoped=true&"
import script from "./navigation.vue?vue&type=script&lang=ts&"
export * from "./navigation.vue?vue&type=script&lang=ts&"
import style0 from "./navigation.vue?vue&type=style&index=0&id=5726be5e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5726be5e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VItem,VItemGroup,VNavigationDrawer})
